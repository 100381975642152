<template>
  <div class="container">
    <div class="mt-4 mb-4">

      <div v-if="loading" class="d-flex justify-content-center mt-5 mb-5">
        <Preloader/>
      </div>
      <div v-else-if="graduateWorkStudents.students.length">
        <h4 class="text-center mb-4">Список студентов</h4>

        <DataTable :value="graduateWorkStudents.students" :paginator="true" :rows="10"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[10,20,50]"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                   stripedRows showGridlines responsiveLayout="scroll">


          <Column header="ФИО студента">
            <template #body="{data}">
              <div v-for="(student, studentIndex) in data.students" :key="'student'+studentIndex" class="mb-2">
                <div v-if="studentIndex===0">
                  <router-link :to="`/graduate-work?theme_id=${data.id}`" target="_blank">
                    {{student.student_data_last_name}} {{student.student_data_first_name}}
                  </router-link>
                </div>
                <div v-else>
                  {{student.student_data_last_name}} {{student.student_data_first_name}}
                </div>
              </div>
            </template>
          </Column>


          <Column field="theme_name_kz" header="Название темы на казахском"></Column>
          <Column field="theme_name_ru" header="Название темы на русском"></Column>
          <Column field="theme_name_en" header="Название темы на английском"></Column>

          <Column field="study_level_name" header="Уровень обучения"></Column>

          <Column header="ОП">
            <template #body="{data}">
              {{data.education_speciality_code}} - {{data.education_speciality_name}}
            </template>
          </Column>

        </DataTable>
      </div>
      <div v-else>
        <h4 class="text-center mb-4">Здесь пусто</h4>
      </div>

    </div>
  </div>
</template>


<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

  export default {
    name: "GraduateWorkStudentsList",

    data() {
      return {
        loading: true
      }
    },
    computed: {
      ...mapState('graduateWorkStudents', ['graduateWorkStudents']),
    },
    methods: {
      ...mapActions('graduateWorkStudents', ['GET_STUDENTS_BY_SUPERVISOR_ID']),
      // ...mapMutations('uploadGraduateWork', ['SET_GRADUATE_WORK_CHAPTER_FILE']),

    },

    async mounted() {
      await this.GET_STUDENTS_BY_SUPERVISOR_ID()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>